.popup{
	&-view{
		padding: 0;
		background: transparent;
	}
	&-image{
		width: 100%;
	}
	&-form{
		background-color: $white;
	}
}