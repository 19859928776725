.contact{
	&-title {
		margin-bottom: rem-calc(32);
		color: $black;
		text-align: center;
    }
    .section1{
    	max-height: 600px;
    	margin:{
    		left: rem-calc(12);
    		right: rem-calc(12);
    		bottom: rem-calc(25);
    	}
    	background-color: $light-gray;
    	.title{
    		margin-bottom: rem-calc(24);
    		text-align: center;
		    letter-spacing: rem-calc(16);
		    line-height: 1.6;
    	}
    	.form{
			@include custom-form;
			@include breakpoint(large){
				padding: rem-calc(30) rem-calc(100);
			}
			.btn{
				@include custom-button;
			}
	    }
	    .image{
	    	display: none;
	    	@include breakpoint(large){
	    		display: block;
	    	}
	    	object-fit: cover;
	    	img{
	    		height: 100%;
	    	}
	    }
    }
	&-group{
		justify-content: center;
		@include breakpoint(large){
			justify-content: flex-start;
		}
		.section{
			display: block;
			padding:{
				left: rem-calc(12);
				right: rem-calc(12);
			}
			margin-bottom: rem-calc(16);
			p{
				margin-bottom: 5px;
			}
			.title{
				margin-bottom: rem-calc(5);
				text-align: center;
				font-weight: $global-weight-bold;
				@include breakpoint(medium){
					text-align: left;
				}
			}
			.address{
				width: 100%;
				margin-bottom: 0;
				text-align: center;
				font-size: 14.5px;
				@include breakpoint(medium){
					text-align: left;
					width: 250px;
				}
			}
			.time{
				font-size: 15px;
				weight: 550;
				text-align: center;
				@include breakpoint(medium){
					text-align: left;
				}
			}
			&-item{
				display: flex;
				justify-content: center;
				margin-bottom: rem-calc(10);
				@include breakpoint(medium){
					justify-content: flex-start;
				}
				.icon{
					height: 100%;
					padding:{
						left: rem-calc(10);
						right: rem-calc(10);
					}
					margin: auto 0;
				}
			}
		}
	}
}