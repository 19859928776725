.orbit {
    margin:{
        left: rem-calc(8);
        right: rem-calc(8);
        bottom: rem-calc(20);
    }
    &-image {
        height: 320px;
        object-fit: cover;
        @include breakpoint(medium) {
            height: 480px;
        }
        @include breakpoint(large) {
            height: 592px;
        }
    }
    &-bullets {
        margin-top: -30px;
        @include breakpoint(medium){
            margin-top: -50px;
        }
        button{
            border: 2.5px solid $white;
            &.is-active{
                background-color: transparent;
                border-color: $white;
            }
        }
    }
}