@mixin custom-form{
	display: block;
	padding: rem-calc(20);
	margin: auto;
	@include breakpoint(medium){
		padding: rem-calc(20) rem-calc(40);
	}
	input, textarea{
		padding: rem-calc(20);
		border: 0;
	}
}

@mixin custom-button{
	font-weight: 600;
	background-color: $dark-raspberry;
	&:hover{
		background-color: $darken-raspberry;
	}
}

@mixin we-reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}