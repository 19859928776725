%logo-set{
	height: rem-calc(70);
	padding: {
		top: rem-calc(2);
		bottom: rem-calc(2);
	}
}

.header{
	width: 100%;
	height: $header-height;
	padding:{
		top: $header-padding;
		bottom: $header-padding;
	}
	background-color: $white;
	@include breakpoint(large){
		height: $header-height-large;
	}
	&-logo{
		display: block;
		@extend %logo-set;
		margin:{
			left: auto;
			right: auto;
			top: rem-calc(8);
			bottom: rem-calc(8);
		}
		@include breakpoint(large){
			height: rem-calc(80);
			margin:{
				top: rem-calc(20);
				bottom: rem-calc(20);
			}
		}
		@include breakpoint(large){
			height: rem-calc(90);
			margin:{
				top: rem-calc(22);
				bottom: rem-calc(22);
			}
		}
		img {
	      height: 100%;
	      margin-top: 0;
	    }
	}
	&-nav{
		display: flex;
		justify-content: center;
		ul{
			@include we-reset-list;
			position: relative;
			display: flex;
			width: auto;
			padding-left: 0;
			margin-top: rem-calc(15);
			opacity: 1;
			visibility: visible;
			li {
				border-bottom: 0;
				margin: {
				  left: rem-calc(7);
				  right: rem-calc(7);
				  bottom: 0;
				}
				@include breakpoint(medium){
					margin:{
						left: rem-calc(24);
				  		right: rem-calc(24);
					}
				}
				&.link{
					font-weight: 600;
					a:hover,
					a.is-active{
						color: $dark-raspberry;
					}
				}
			}
		}
	}
}