%margin-section{
	margin:{
    	left: rem-calc(8);
    	right: rem-calc(8);
    }
}

.grid-x{
	margin-left: 0;
}
.home{
	.title{
		margin-bottom: rem-calc(14);
		text-align: center;
		@include breakpoint(medium){
			margin-bottom: rem-calc(20);
		}
	}
	&-banner{
		margin-bottom: rem-calc(20);
		.banner-bottom{
			margin:{
				top: rem-calc(40);
				bottom: rem-calc(20);
			}
			.image{
			    position: relative;
			    display: block;
			    padding:{
			    	left: rem-calc(8);
			    	right: rem-calc(8);
			    	bottom: rem-calc(16);
			    }
				object-fit: cover;
			    img{
			    	width: 100%;
			    	height: 100%;
			    }
			    .text{
			    	position: absolute;
			    	top: 45%;
				    left: 50%;
				    transform: translate(-50%, -50%);
			    }
			}
		}
	}
	&-hope{
		@extend %margin-section;
	    .more-stories{
	    	position: relative;
	    	width: 100%;
	    	display: block;
	    	margin: rem-calc(24) auto;
	    	.link{
	    		width: 230px;
	    		display: block;
	    		padding: rem-calc(10) rem-calc(25);
	    		margin: auto;
		    	text-align: center;
		    	font-weight: 600;
		    	border: 1px solid $black;
		    	cursor: pointer;
		    	transition: .3s;
		    	&:hover{
		    		background-color: $dark-raspberry;
		    		color: $white;
		    		transition: .3s;
		    	}
	    	}
	    }
	}
	&-form{
		@extend %margin-section;
		.form{
			width: 100%;
			@include custom-form;
			background:{
				image: url('../img/img-2.png');
				repeat: no-repeat;
				size: cover;
			}
			.custom-input{
				padding: 0 rem-calc(8);
			}
			@include breakpoint(large){
				padding: rem-calc(30) rem-calc(190);
			}
			.title{
	    		margin-bottom: rem-calc(24);
	    		text-align: center;
			    letter-spacing: rem-calc(16);
			    line-height: 1.6;
	    	}
			.btn{
				@include custom-button;
			}
		}
	}
	&-product{
		margin-bottom: rem-calc(8);
	}
}