.about{
	padding:{
		left: rem-calc(15);
		right: rem-calc(15);
		top: rem-calc(16);
	}
	&-cover{
		width: 100%;
		object-fit: cover;
	}
	.title{
		margin-bottom: rem-calc(14);
		text-align: center;
		@include breakpoint(medium){
			margin-bottom: rem-calc(20);
		}
	}
	&-explanation{
		padding: rem-calc(10);
		margin-bottom: rem-calc(25);
		text-align: center;
		@include breakpoint(medium){
			padding: rem-calc(20);
		}
		.description,
		.vision,
		.mision{
			padding:{
				left: rem-calc(5);
				right: rem-calc(5);
			}
		}
		.description{
			margin-bottom: rem-calc(20);
			@include breakpoint(large){
				padding:{
					left: rem-calc(60);
					right: rem-calc(60);
				}
				margin-bottom: rem-calc(40);
			}
		}
		.vision,
		.mision{
			@include breakpoint(large){
				padding:{
					left: rem-calc(20);
					right: rem-calc(20);
				}
			}
		}
	}
}