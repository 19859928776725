.sosmed{
	position: fixed;
    z-index: 2;
    right: rem-calc(10);
    top: 35%;
    background-color: $light-gray;
    @media only screen and (min-device-width: 320px) and (orientation: landscape) {
		top: 30%;
	}
    @include breakpoint(large){
    	right: rem-calc(40);
    }
    &-list{
    	@include we-reset-list;
    	padding:{
    		top: rem-calc(5);
    		bottom: rem-calc(5);
    	}
    	&-item{
			padding: rem-calc(6) rem-calc(14);
			@include breakpoint(medium){
				padding: rem-calc(10) rem-calc(20);
			}
			img{
				display: block;
				margin: 0 auto;
			}
    	}
    }
}