.footer{
	border-top: 1.25px solid $medium-gray;
	&-top{
		padding:{
			top: rem-calc(14);
			bottom: rem-calc(14);
		}
		background-color: $white;
		.title{
			text-align: center;
			font-weight: $global-weight-bold;
			@include breakpoint(medium){
				text-align: left;
			}
		}
		.section1{
			padding:{
				left: rem-calc(12);
				right: rem-calc(12);
			}
			.address{
				text-align: center;
				font:{
					size: 15.5px;
					weight: 570;
				}
				@include breakpoint(medium){
					text-align: left;
				}
				@include breakpoint(large){
					margin-right: rem-calc(80);
				}
			}
		}
		.section2{
			display: block;
			padding:{
				left: rem-calc(12);
				right: rem-calc(12);
			}
			.time{
				font-size: 15px;
				weight: 550;
				text-align: center;
				@include breakpoint(medium){
					text-align: left;
				}
			}
			.contact{
				display: flex;
				justify-content: center;
				margin-bottom: rem-calc(20);
				@include breakpoint(medium){
					justify-content: flex-start;
				}
				.icon{
					height: 100%;
					padding:{
						left: rem-calc(10);
						right: rem-calc(10);
					}
					margin: auto 0;
				}
			}
		}
		.section3{
			display: block;
			margin:{
				left: auto;
				right: auto;
			}
			.title{
			text-align: center;
			font-weight: $global-weight-bold;
			@include breakpoint(large){
					text-align: left;
				}
			}
			.description{
				display: flex;
				justify-content: center;
				margin-bottom: rem-calc(8);
				.image{
					width: 60px;
					height: 60px;
					padding: 6px;
					background-color: $dark-raspberry;
					img{
						width: 100%;
					}
				}
				.text{
					max-width: 360px;
					margin-left: rem-calc(10);
					h6, p{
						margin-bottom: 0;
					}
					.name{
						line-height: 1.2;
					}
					.bio{
						font-size: 12px;
					}
				}
			}
			&-list{
				width: 100%;
				@include we-reset-list;
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				margin: {
					left: -5px;
					right: -5px;
				}
				.item{
					display: block;
					margin: 2px;
					img{
						width: 90px;
					}
				}
			}
		}
	}
	&-bottom{
		padding: 1em 20px;
		text-align: center;
		font-size: 1em;
	    color: $white;
	    background-color: $dark-raspberry;
	}
}