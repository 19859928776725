%product-image {
	display: block;
	width: 100%;
}

%product-text {
	margin: 12px;
	text-align: center;
}

.product{
	.category{
		padding:{
			left: rem-calc(10);
			right: rem-calc(10);
		}
		margin-bottom: rem-calc(20);
		@include breakpoint(medium){
			padding:{
				left: rem-calc(20);
				right: rem-calc(20);
			}
		}
		&-title{
			width: 100%;
			padding-bottom: rem-calc(13);
			border-bottom: 1.5px solid $light-gray;
		}
		.subtitle{
			margin-top: rem-calc(16);
			text-align: center;
			@include breakpoint(large){
				text-align: left;
			}
		}
		&-list{
			width: 100%;
			@include we-reset-list;
			&-item{
				width: 100%;
				padding-left: 0;
				color: $black;
				text-align: center;
				&:hover{
					background-color: $dark-raspberry;
					a{
						color: $white;
					}
				}
				@include breakpoint(large){
					padding-left: rem-calc(5);
					text-align: left;
				}
			}
		}
	}
	.custom-select {
		width: 100%;
		margin-bottom: rem-calc(16);
		background: {
			image: url(../img/ic-arrow-down.png);
			repeat: no-repeat;
			position: right rem-calc(8) center;
		}
		border: 1px solid $black;
		@include breakpoint(medium){
			width: rem-calc(240);
			margin:{
				left: auto;
				right: rem-calc(16);
			}
		}
		.field {
			background: {
				color: transparent;
				image: none;
			}
		}
		select{
			padding: rem-calc(4) rem-calc(16);
			margin-bottom: 0;
			border: 0;
		}
	}
	&-list{
		display: flex;
		justify-content: flex-start;
	    padding: 0;
	    list-style: none;
	    li {
	      margin-bottom: rem-calc(16);
	    }
	}
	&-padding{
		padding: 0;
		@include breakpoint(medium){
			padding:{
	    		left: rem-calc(8);
	    		right: rem-calc(8);
	    	}
		}
    }
	&-block {
	    display: block;
	    position: relative;
	    margin: 0 rem-calc(8);
	    width: auto;
	    height: auto;
	    object-fit: cover;
	    .image {
			@extend %product-image;
			position: relative;
			height: rem-calc(318);
			object-fit: cover;
			margin-bottom: rem-calc(8);
	    }
	    .image-small {
			@extend %product-image;
			position: relative;
			height: rem-calc(250);
			object-fit: cover;
			margin-bottom: rem-calc(8);
	    }
	    .name {
	    	@extend %product-text;
	    }
	   	.price{
			@extend %product-text;
		}
	}
	&-detail{
		margin:{
			top: rem-calc(30);
			bottom: rem-calc(30);
		}
		@include breakpoint(large){
			margin-top: rem-calc(60);
		}
		.image{
			position: relative;
			object-fit: cover;
			&-product{
				width: 100%;
			}
			&-zoom{
				position: absolute;
				top: rem-calc(16);
				right: rem-calc(16);
				display: block;
				z-index: 2;
			}
		}
		&-info{
			padding: rem-calc(15);
			@include breakpoint(medium){
				padding: rem-calc(20);
			}
			@include breakpoint(large){
				padding: 0 rem-calc(60);
			}
			.top{
				padding-bottom: rem-calc(8);
				border-bottom: 1.5px solid $light-gray;
				.name{
					letter-spacing: .6px;
					line-height: 1;
				}
				.price{
					font-weight: 500;
					color: $dark-gray;
				}
				@include breakpoint(medium){
					padding-bottom: rem-calc(16);
				}
			}
			.bottom{
				padding-top: rem-calc(6);
				@include breakpoint(medium){
					padding-top: rem-calc(16);
				}
				.title{
					margin:{
						top: rem-calc(5);
						bottom: rem-calc(9);
					}
					@include breakpoint(medium){
						margin:{
							top: rem-calc(10);
							bottom: rem-calc(18);
						}
					}
				}
				.info{
					color: $dark-gray;
				}
			}
		}
	}
}