.breadcrumb {
    margin-bottom: rem-calc(24);
        ul {
            @include we-reset-list;
            li {
                display: inline-block;
                margin-right: rem-calc(4);
                &:nth-child(n+2) {
                    position: relative;
                    padding-left: rem-calc(14);
                    &:after {
                        content: '/';
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: $black;
                    }
                }
            }
        }
        &-link {
            display: block;
            font-weight: 600;
            color: $black;
        &.is-active {
            font-weight: 500;
            color: $dark-gray;
        }
    }
}