@font-face {
    font-family: DIN;
    src: url('../font/DIN.ttf');
}
a {
	color: $black;
	&:hover {
		color: inherit;
	}
}
h3{
	font-size: 1.9375rem;
}
h1,h2,h3,h4,h5,h6{
	font-weight: 600;
}
body{
	font-family: DIN;
	font-size: .875em;
	@include breakpoint(medium) {
	    font-size: 1em;
	}
}

.fade-animate{
	opacity: 0;
	transition: transform .3s ease-in-out;
	transform: translateY(35px);
}
.anim{
	transform: translateY(0);
}

.hopes{
	.title{
		margin-bottom: rem-calc(14);
		text-align: center;
		@include breakpoint(medium){
			margin-bottom: rem-calc(20);
		}
	}
	&-item{
		border: 1px solid $medium-gray;
		.image,
		.image2,
		.image3{
		    position: relative;
		    height: rem-calc(200);
		    object-fit: cover;
		    img{
		    	width: 100%;
		    	height: 100%;
		    }
		    @media (min-width: 640px) and (max-width: 1024px) and (orientation: landscape){
		    	height: rem-calc(300);
		    }
		    @include breakpoint(large){
		    	height: rem-calc(350);
		    }
		}
		.image{
			display: block;
		}
		.image2{
			display: block;
			@include breakpoint(medium){
				display: none;
			}
		}
		.image3{
			display: none;
			@include breakpoint(medium){
				display: block;
			}
		}
		.desc{
			text-align: center;
			padding: rem-calc(20);
			margin: auto;
			@include breakpoint(medium){
				padding:{
					left: rem-calc(50);
					right: rem-calc(50);
				}
			}
			p{
				margin-bottom: rem-calc(5);
			}
		}
	}
}
