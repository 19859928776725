.main {
    position: relative;
    min-height: 70vh;
    padding: {
        top: rem-calc(12);
        bottom: rem-calc(12);
    }
    background-color: $white;
    @include breakpoint(large){
      padding: {
        top: rem-calc(24);
        bottom: rem-calc(24);
      }
    }
    &-index {
        min-height: 92.31vh;
        padding: {
            top: 0;
            bottom: 0;
        }
        margin-top: 0;
        background-color: $white;
    }

    .logo {
        &-index {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}