.faq{
	padding:{
		left: rem-calc(15);
		right: rem-calc(15);
	}
    background-color: $white;
    @include breakpoint(large){
    	padding:{
			left: rem-calc(115);
			right: rem-calc(115);
		}
    }
    .title {
		margin-bottom: rem-calc(32);
		font-weight: 600;
		color: $black;
		text-align: center;
    }
    .list {
		margin: {
			bottom: 0;
			left: 0;
		}
		list-style: none;
		&-question {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: rem-calc(16);
			&-button {
				position: relative;
				display: inline-block;
				width: rem-calc(32);
				height: rem-calc(32);
				padding: rem-calc(14);
				background:{
					image: url('../img/ic-arrow-down.png');
					repeat: no-repeat;
					position: center;
				}
			}
			&-text {
				margin: {
					left: rem-calc(16);
					bottom: 0;
				}
				color: $black;
				font-weight: 600;
				line-height: 1.25em;
			}
		}
		&-answer {
			display: none;
			padding-left: rem-calc(20);
			margin: {
				top: rem-calc(10);
				bottom: rem-calc(20);
			}
			color: $dark-gray;
			line-height: 1.4;
			.fa-arrow-right{
				margin-right: rem-calc(4);
			}
		}
		.js-faq{
			margin-bottom: rem-calc(16);
			border-bottom: 1px solid $light-gray;
			cursor: pointer;
			&.is-active {
			  	.list-question-button {
			    background:{
			      	image: url('../img/ic-arrowup.png');
			      	repeat: no-repeat;
			      	position: center;
			      }
			  }
			  	.list-answer{
			  		display: block;
			  	}
			}
		}
    }
}